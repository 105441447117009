import React, { useEffect, useRef } from 'react';

const CursorEffect = () => {
  const canvasRef = useRef(null);
  const particlesRef = useRef([]);
  const mouseRef = useRef({ x: 0, y: 0 });
  const frameRef = useRef();
  const isMouseInWindowRef = useRef(true);
  const isMovingRef = useRef(false);
  const moveTimeoutRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const createParticle = (x, y) => ({
      x,
      y,
      size: Math.random() * 0.8 + 0.2,
      speedX: (Math.random() - 0.5) * 0.8,
      speedY: Math.random() * 0.8,
      life: 1
    });

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      if (isMovingRef.current && isMouseInWindowRef.current) {
        for (let i = 0; i < 3; i++) {
          if (Math.random() < 0.9) {
            particlesRef.current.push(
              createParticle(
                mouseRef.current.x + (Math.random() - 0.5) * 10,
                mouseRef.current.y + (Math.random() - 0.5) * 10
              )
            );
          }
        }
      }

      ctx.shadowBlur = 2;
      ctx.shadowColor = 'rgba(255, 255, 255, 0.5)';

      particlesRef.current.forEach((p, index) => {
        p.x += p.speedX;
        p.y += p.speedY;
        p.life -= 0.015;

        if (p.life <= 0) {
          particlesRef.current.splice(index, 1);
          return;
        }

        ctx.beginPath();
        ctx.arc(p.x, p.y, p.size, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(255, 255, 255, ${p.life * 0.8})`;
        ctx.fill();
      });

      frameRef.current = requestAnimationFrame(animate);
    };

    const handleMouseMove = (e) => {
      mouseRef.current = { x: e.clientX, y: e.clientY };
      isMovingRef.current = true;
      
      clearTimeout(moveTimeoutRef.current);
      moveTimeoutRef.current = setTimeout(() => {
        isMovingRef.current = false;
      }, 50);
    };

    const handleMouseEnter = () => {
      isMouseInWindowRef.current = true;
    };

    const handleMouseLeave = () => {
      isMouseInWindowRef.current = false;
    };

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseenter', handleMouseEnter);
    window.addEventListener('mouseleave', handleMouseLeave);
    window.addEventListener('resize', handleResize);
    animate();

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseenter', handleMouseEnter);
      window.removeEventListener('mouseleave', handleMouseLeave);
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(frameRef.current);
      clearTimeout(moveTimeoutRef.current);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="fixed inset-0 pointer-events-none z-50"
    />
  );
};

export default CursorEffect;