import React, { useState, useEffect } from 'react';
import { Clock, Book } from 'lucide-react';
import BuildingInPublic from './Chronicles/BuildingInPublic';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

const ChroniclesContent = ({ isOpen, onClose }) => {
    const [isMinimized, setIsMinimized] = useState(false);
    const [isMaximized, setIsMaximized] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [showMainWindow, setShowMainWindow] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

  useEffect(() => {
    if (!isOpen) {
      setIsMinimized(false);
      setIsMaximized(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const postSlug = location.pathname.split('/chronicles/')[1];
    if (postSlug) {
        setShowMainWindow(false);
    } else {
        setShowMainWindow(true);
    }
  }, [location]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
        navigate('/');
        setIsClosing(false);
    }, 300);
  };

  const handlePostClick = (slug) => {
    navigate(`/chronicles/${slug}`);
  };

  if (!isOpen) return null;

  return (
    <>
      <Routes>
        <Route path="building-in-public" element={
            <BuildingInPublic onClose={() => navigate('/chronicles')} />
        } />
      </Routes>

      {showMainWindow && (
        <div className={`fixed inset-0 flex items-center justify-center z-50 transition-all duration-300 p-6
                        ${!showMainWindow ? 'opacity-0 scale-95 pointer-events-none' : 'opacity-100 scale-100'}`}>
          <div 
            className={`bg-black/30 backdrop-blur-md rounded-xl shadow-2xl border border-white/10 
                       transition-all duration-300 ease-out
                       ${isMaximized ? 'w-full h-full rounded-none' : 'w-[800px] h-[600px]'}
                       ${isMinimized ? 'scale-0 opacity-0' : 'scale-100 opacity-100'}
                       ${isClosing ? 'scale-95 opacity-0' : ''}`}
          >
            <div className="flex items-center p-3 border-b border-white/10">
              <div className="flex gap-2">
                <button 
                  onClick={handleClose}
                  className="w-3 h-3 rounded-full bg-red-500 hover:bg-red-600 relative group transition-colors"
                >
                  <span className="absolute inset-0 flex items-center justify-center text-[13px] text-black/80 opacity-0 group-hover:opacity-100">×</span>
                </button>
                <button
                  onClick={handleClose}
                  className="w-3 h-3 rounded-full bg-yellow-500 hover:bg-yellow-600 relative group transition-colors"
                >
                  <span className="absolute inset-0 flex items-center justify-center text-[13px] text-black/80 opacity-0 group-hover:opacity-100">−</span>
                </button>
                <button
                  onClick={() => setIsMaximized(!isMaximized)}
                  className="w-3 h-3 rounded-full bg-green-500 hover:bg-green-600 relative group transition-colors"
                >
                  <span className="absolute inset-0 flex items-center justify-center text-[13px] text-black/80 opacity-0 group-hover:opacity-100">+</span>
                </button>
              </div>
            </div>
            
            <div className="p-8 text-white/90 h-[calc(100%-3rem)] overflow-y-auto">
              <h2 className="font-mono text-3xl mb-2">Chronicles_</h2>
              <p className="font-mono text-sm text-white/50 mb-8">my thoughts</p>
              <div className="space-y-8">
                {posts.map((post, index) => (
                  <div key={index} 
                       onClick={() => handlePostClick(post.slug)}
                       className="border border-white/10 rounded-lg p-6 backdrop-blur-sm hover:bg-white/5 transition-all cursor-pointer">
                    <h3 className="font-mono text-xl mb-2">{post.title}</h3>
                    <p className="text-white/70 mb-4 font-mono text-sm">{post.excerpt}</p>
                    <div className="flex items-center gap-4 text-xs text-white/50 font-mono">
                      <div className="flex items-center gap-1">
                        <Clock className="w-3 h-3" />
                        {post.date}
                      </div>
                      <div className="flex items-center gap-1">
                        <Book className="w-3 h-3" />
                        {post.readTime}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const posts = [
  {
    title: "The Art of Building in Public",
    slug: "building-in-public",
    excerpt: "Sharing the journey of creation, one step at a time. How transparency builds trust and community...",
    date: "Dec 1, 2023",
    readTime: "5 min read"
  },
  {
    title: "Future of Human-AI Collaboration", 
    slug: "human-ai-collaboration",
    excerpt: "Exploring the symbiotic relationship between human creativity and artificial intelligence...",
    date: "Nov 15, 2023",
    readTime: "8 min read"
  },
  {
    title: "Minimalism in Digital Design",
    slug: "minimalism-in-digital-design",
    excerpt: "Less is more: How constraint breeds creativity in the digital age...",
    date: "Oct 28, 2023",
    readTime: "6 min read"
  }
];

export default ChroniclesContent;