import React, { useState, useEffect } from 'react';
import { MapPin, Building } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const QuoteBlock = ({ text, author, source, borderColor = "blue" }) => {
  const borderColorClasses = {
    blue: "border-blue-400",
    purple: "border-purple-400",
    green: "border-green-400"
  };

  return (
    <div className={`my-12 bg-white/5 backdrop-blur-sm ${borderColorClasses[borderColor]} border-l-4 pl-6 py-6 
                     transform transition-all duration-300 hover:translate-x-2`}>
      <p className="text-lg sm:text-xl font-light italic leading-relaxed">"{text}"</p>
      <p className="text-sm text-white/60 mt-3 italic font-mono">— {author}, <span>{source}</span></p>
    </div>
  );
};

const StoryWindow = ({ isOpen, onClose }) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [isMaximized, setIsMaximized] = useState(true);
  const [isClosing] = useState(false);
  const [activeSection, setActiveSection] = useState('story');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isOpen) {
      setIsMinimized(false);
      setIsMaximized(false);
    }
  }, [isOpen]);

  const handleClose = () => {
    navigate('/');
  };

  const interests = [
    { icon: "🛠️", label: "Building" },
    { icon: "💻", label: "Coding" },
    { icon: "🏋️", label: "Workout" },
    { icon: "🤝", label: "Meeting People" },
    { icon: "♟️", label: "Chess" },
    { icon: "⚽", label: "Football" },
    { icon: "🏐", label: "Volleyball" },
    { icon: "✍️", label: "Writing" },
    { icon: "📚", label: "Fiction" },
    { icon: "🧠", label: "Psychology" },
    { icon: "🌱", label: "Gardening" },
    { icon: "🎥", label: "FRIENDS" }
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 p-6">
      <div className={`bg-black/30 backdrop-blur-xl rounded-xl shadow-2xl border border-white/10 
                    transition-all duration-300 ease-out overflow-hidden
                    ${isMaximized ? 'w-full h-full rounded-none' : 'w-[900px] h-[700px]'}
                    ${isMinimized ? 'scale-0 opacity-0' : 'scale-100 opacity-100'}
                    ${isClosing ? 'scale-95 opacity-0' : ''}`}>
        <div className="flex items-center p-3 border-b border-white/10 bg-black/20">
          <div className="flex gap-2">
            <button 
              onClick={handleClose}
              className="w-3 h-3 rounded-full bg-red-500 hover:bg-red-600 relative group transition-colors"
            >
              <span className="absolute inset-0 flex items-center justify-center text-[13px] text-black/80 opacity-0 group-hover:opacity-100">×</span>
            </button>
            <button
              onClick={handleClose}
              className="w-3 h-3 rounded-full bg-yellow-500 hover:bg-yellow-600 relative group transition-colors"
            >
              <span className="absolute inset-0 flex items-center justify-center text-[13px] text-black/80 opacity-0 group-hover:opacity-100">−</span>
            </button>
            <button
              onClick={() => setIsMaximized(!isMaximized)}
              className="w-3 h-3 rounded-full bg-green-500 hover:bg-green-600 relative group transition-colors"
            >
              <span className="absolute inset-0 flex items-center justify-center text-[13px] text-black/80 opacity-0 group-hover:opacity-100">+</span>
            </button>
          </div>
          <div className="flex gap-4 ml-auto">
            <button 
              onClick={() => setActiveSection('story')}
              className={`px-4 py-1 rounded-full text-sm font-mono transition-colors duration-300
                ${activeSection === 'story' ? 'bg-white/10 text-white' : 'text-white/60 hover:text-white'}`}>
              Story
            </button>
            <button 
              onClick={() => setActiveSection('interests')}
              className={`px-4 py-1 rounded-full text-sm font-mono transition-colors duration-300
                ${activeSection === 'interests' ? 'bg-white/10 text-white' : 'text-white/60 hover:text-white'}`}>
              Pieces of Me
            </button>
          </div>
        </div>

        <div className="h-[calc(100%-3rem)] overflow-y-auto">
          {activeSection === 'story' ? (
            <div className="max-w-4xl mx-auto p-8 text-white/90">
              <div className="space-y-8">
                <div className="mb-8">
                  <h2 className="font-mono text-2xl sm:text-3xl mb-2">Origins_</h2>
                  <p className="font-mono text-sm text-white/50">my story so far</p>
                </div>

                <p className="text-base sm:text-lg leading-relaxed">Hey, how you doin'?</p>

                <p className="text-base sm:text-lg leading-relaxed">I've always believed that life's most interesting paths are the ones we carve ourselves, no matter how unconventional they may seem. My path started at age 16, propelled by passion and a go-against-the-flow mentality which eliminated redundant options from day one. After high school, despite securing admission to some of the most prestigious universities in India, I decided not to go.</p>

                <p className="text-base sm:text-lg leading-relaxed">So at 18, I jumped right into the startup world, leaving the books behind to work on something that's raw and real. My first real endeavor? An autonomous robotics startup. It was bold, exciting, and ahead of its time—and let's be honest, it failed miserably. I burned through time, energy, and ambition, leaving me with empty pockets and a notebook full of lessons. One of those lessons? Having money definitely helps. Duh.</p>

                <p className="text-base sm:text-lg leading-relaxed">That dream is still there and I'll build it no matter what it takes, just maybe not now.</p>

                <QuoteBlock 
                  text="The world is not a wish-granting factory."
                  author="The Fault in Our Stars"
                  source="John Green"
                  borderColor="blue"
                />

                <p className="text-base sm:text-lg leading-relaxed">I had nothing to lose so I began again. I created two businesses. Of them, one is a basic, online tutoring and AI-powered assignment help startup and it became popular. It was not very picturesque, but it had the desired effect. I sold it for 5-figures USD in mid-2024. A win, sure, but more importantly, it was proof that I could turn an idea into something tangible, something real. I needed that, I would have never stopped if that went the other way, but too many failures makes you weak you need them wins once in a while. Those wins make everything worthwhile.</p>

                <QuoteBlock 
                  text="Man is not made for defeat. A man can be destroyed but not defeated."
                  author="The Old Man and the Sea"
                  source="Ernest Hemingway"
                  borderColor="purple"
                />

                <p className="text-base sm:text-lg leading-relaxed">In between I worked for a few startups and NGOs. Now, I'm finally back on track, pursuing a dream that gets me excited in the morning. A healthtech platform to help people live better lives, only in its infancy. Working on another project with some of the best people, DraftHood, a consumer social app to connect people through sports.</p>

                <p className="text-base sm:text-lg leading-relaxed">But when I am not consumed with prototypes, business models, or code, my thoughts turn to the kinds of questions that most people don't consider on a Tuesday afternoon. Mainly, how could consciousness be transferred into a robot? What would love look like for sentient AI in the future? Will it be tender, messy, beautiful—like ours—or something entirely different? Will it be us making (influencing) that decision, or will they?</p>

                <p className="text-base sm:text-lg leading-relaxed">Life for me is a blend of curiosity, creation, and competition, and I wouldn't have it any other way. I understood that the purpose of life is not to avoid failure, but to dare to dream of what might be and then not give up. Well, here I am, typing away this chapter, constructing, envisioning, and pursuing the next big thing. Weird? Maybe. But boring? Never.</p>

                <QuoteBlock 
                  text="I am not afraid of storms, for I am learning how to sail my ship."
                  author="Little Women"
                  source="Louisa May Alcott"
                  borderColor="green"
                />

                <p className="text-base sm:text-lg leading-relaxed">I discovered Little Women after watching Friends. Here's the clip:</p>

                <div className="my-12 flex justify-center">
                  <div className="w-3/4 aspect-video rounded-lg overflow-hidden shadow-2xl border border-white/10">
                    <iframe 
                      className="w-full h-full"
                      src="https://www.youtube.com/embed/sgNjdg8-Wyc"
                      title="Little Women scene in Friends"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>

                <p className="text-base sm:text-lg leading-relaxed">Made up my mind to move to the US in 2025. Ready to call San Francisco my home.</p>
                
                <p className="text-base sm:text-lg leading-relaxed">Welcome to my story—we're just getting started.</p>

                <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3 sm:gap-6 text-white/60 text-sm mt-12 font-mono border-t border-white/10 pt-8">
                  <div className="flex items-center gap-2 bg-white/5 px-4 py-2 rounded-lg">
                    <MapPin className="w-4 h-4" />
                    <span>Soon: San Francisco</span>
                  </div>

                  <div className="flex items-center gap-2 bg-white/5 px-4 py-2 rounded-lg">
                    <Building className="w-4 h-4" />
                    <span>Current: Healthtech X AI</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="p-8 max-w-4xl mx-auto">
              <div className="grid grid-cols-2 md:grid-cols-3 gap-3 sm:gap-4">
                {interests.map((interest, index) => (
                  <div key={index} 
                    className="bg-white/5 backdrop-blur-lg p-4 rounded-lg border border-white/10 
                              flex items-center gap-3 hover:bg-white/10 transition-all duration-300
                              hover:scale-105 hover:shadow-lg cursor-pointer">
                    <span className="text-2xl">{interest.icon}</span>
                    <span className="text-white/80 font-mono">{interest.label}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoryWindow;