import { useState, useEffect } from 'react';
import { ArrowRight } from 'lucide-react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import StoryWindow from './StoryWindow';
import WorksContent from './WorksContent';
import ChroniclesContent from './ChroniclesContent';
import ContactWindow from './ContactWindow';
import CursorEffect from './CursorEffect';
import MusicPlayer from './MusicPlayer';

const ProgressRing = ({ progress, label }) => (
  <div className="relative inline-flex items-center justify-center">
    <svg className="w-16 h-16">
      <circle
        className="text-gray-700"
        strokeWidth="4"
        stroke="currentColor"
        fill="transparent"
        r="30"
        cx="32"
        cy="32"
      />
      <circle
        className="text-blue-500"
        strokeWidth="4"
        strokeDasharray={30 * 2 * Math.PI}
        strokeDashoffset={30 * 2 * Math.PI * (1 - progress)}
        strokeLinecap="round"
        stroke="currentColor"
        fill="transparent"
        r="30"
        cx="32"
        cy="32"
        style={{ transform: 'rotate(-90deg)', transformOrigin: '32px 32px' }}
      />
    </svg>
    <div className="absolute flex flex-col items-center">
      <span className="text-sm font-mono">{(progress * 100).toFixed(0)}%</span>
      <span className="text-xs text-gray-400">{label}</span>
    </div>
  </div>
);

const HomePage = () => {
  const [hoveredLink, setHoveredLink] = useState(null);
  const [time, setTime] = useState(new Date());
  const [, setActiveWindow] = useState(null);
  const [contentVisible, setContentVisible] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  // const [currentBg] = useState(() => Math.floor(Math.random() * 8) + 1);
  const [currentBg] = useState((4));
  const [progress, setProgress] = useState({
    day: 0,
    month: 0,
    year: 0
  });
  
  useEffect(() => {
    const timer = setInterval(() => {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const now = new Date().toLocaleString('en-US', { timeZone: userTimezone });
      const parsedNow = new Date(now);
      const localStartOfDay = new Date(parsedNow).setHours(0,0,0,0);
      const localStartOfMonth = new Date(parsedNow.getFullYear(), parsedNow.getMonth(), 1);
      const localStartOfYear = new Date(parsedNow.getFullYear(), 0, 1);
      const localEndOfYear = new Date(parsedNow.getFullYear() + 1, 0, 1);
  
      setTime(parsedNow);
      setProgress({
        day: (parsedNow - localStartOfDay) / (24 * 60 * 60 * 1000),
        month: (parsedNow - localStartOfMonth) / (localEndOfYear - localStartOfYear) * 12,
        year: (parsedNow - localStartOfYear) / (localEndOfYear - localStartOfYear)
      });
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    // Set active window based on current path
    const path = location.pathname.substring(1); // Remove leading slash
    if (path) {
      setActiveWindow(path);
      setContentVisible(false);
    } else {
      setActiveWindow(null);
      setTimeout(() => setContentVisible(true), 300);
    }
  }, [location]);

  const handleWindowClose = () => {
    navigate('/');
  };

  // useEffect(() => {
  //   const bgInterval = setInterval(() => {
  //     let randomNum;
  //     do {
  //       randomNum = Math.floor(Math.random() * 8) + 1;
  //     } while (randomNum === currentBg);
      
  //     setCurrentBg(randomNum);
      
  //     // Track used images and reset when all are used
  //     const newUsedImages = new Set(usedImages);
  //     newUsedImages.add(randomNum);
  //     if (newUsedImages.size === 10) {
  //       setUsedImages(new Set([randomNum]));
  //     } else {
  //       setUsedImages(newUsedImages);
  //     }
  //   }, 33000);

  //   return () => clearInterval(bgInterval);
  // }, [currentBg, usedImages]);

  useEffect(() => {
    // Preload the background image
    const img = new Image();
    img.src = `/images/img4.webp`;
  }, [currentBg]);

  const links = [
    { 
      title: 'Origins', 
      path: 'origins',
      onClick: () => navigate('/origins'),
      description: 'my story so far',
      content: <StoryWindow/>
    },
    { 
      title: 'Laboratory',
      path: 'laboratory',
      onClick: () => navigate('/laboratory'),
      description: 'experiments and explorations',
      content: <WorksContent />
    },
    { 
      title: 'Chronicles',
      path: 'chronicles',
      onClick: () => navigate('/chronicles'),
      description: 'my thoughts',
      content: <ChroniclesContent />
    },
    {
      title: "Reach Out",
      path: "contact",
      onClick: () => navigate('/contact'),
      description: "let's build something together",
      content: <ContactWindow />
    }
  ];

  return (
    <div className="h-screen w-screen overflow-y-auto overflow-x-hidden relative">
      <CursorEffect />
      <div 
        className="absolute inset-0 bg-black"
        style={{
          backgroundImage: `url('/images/img${currentBg}.webp')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          filter: 'brightness(0.5)',
          transition: 'opacity 0.3s ease-in-out',
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
      />
      
      <div className={`fixed top-8 right-8 text-white/90 transition-all duration-300 z-50
                ${contentVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4'}`}>
        <div className="backdrop-blur-lg bg-black/20 p-3 sm:p-6 rounded-lg border border-white/10">
          <div className="font-mono text-lg mb-4">
            <div className="text-2xl font-light mb-1">
              {time.toLocaleTimeString('en-US', { hour12: false })}
            </div>
            <div className="text-sm text-white/70">
              {time.toLocaleDateString('en-US', { 
                weekday: 'long', 
                year: 'numeric', 
                month: 'long', 
                day: 'numeric' 
              })}
            </div>
          </div>
          
          <div className="flex gap-4 items-center justify-center">
            <ProgressRing progress={progress.day} label="Day" />
            <ProgressRing progress={progress.month} label="Month" />
            <ProgressRing progress={progress.year} label="Year" />
          </div>
          
          <div className="text-sm text-red-400/80 mt-4 italic text-center">
            you are running out of time...
          </div>
        </div>
      </div>
      
      <div className={`relative h-full flex flex-col justify-center px-4 sm:p-8 lg:p-16 pt-20 transition-all duration-300
                ${contentVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}>
        <div className="max-w-4xl">
        <h1 className="font-mono text-4xl font-bold text-white mb-4 motion-safe:animate-fade-in-down">
          Azhar
        </h1>
        <p className="font-mono text-xl text-white mb-8 motion-safe:animate-fade-in-up delay-200">
          Builder, 21
        </p>
        <p className="font-mono text-md sm:text-md text-white mb-12 max-w-2xl mt-8 whitespace-pre-line overflow-hidden text-ellipsis">
          koinophobic. building products that matter. currently building in health tech. love stories, marketing, coding, writing and working out.
        </p>

          <div className="flex flex-col gap-4 mb-12 w-full sm:w-auto sm:max-w-lg">
            {links.map((link, index) => (
              <button
                key={link.title}
                onClick={link.onClick}
                className="group backdrop-blur-xl px-6 py-4 rounded-lg border border-white/10 
                          hover:bg-white/10 hover:scale-[1.02] active:scale-[0.98]
                          transition-all duration-300 ease-out relative overflow-hidden
                          motion-safe:animate-fade-in-up cursor-pointer w-full text-left"
                style={{ animationDelay: `${300 + index * 100}ms` }}
                onMouseEnter={() => setHoveredLink(link.title)}
                onMouseLeave={() => setHoveredLink(null)}
              >
                <div className="animate-border absolute inset-0" />
                <div className="relative z-10">
                  <div className="flex justify-between items-center">
                    <h2 className="font-mono text-lg font-medium text-white">{link.title}</h2>
                    <ArrowRight className={`w-4 h-4 transform transition-transform duration-300 text-white/45
                      ${hoveredLink === link.title ? 'translate-x-1' : ''}`} />
                  </div>
                  <p className="font-mono text-sm text-gray-400 mt-1">{link.description}</p>
                </div>
              </button>
            ))}
            </div>

          <div className="flex gap-6 text-white/70 motion-safe:animate-fade-in-up" 
              style={{ animationDelay: '700ms' }}>
            <a href="https://x.com/azharaio" target="_blank" rel="noopener noreferrer" 
              className="hover:text-white transition-colors duration-300">
              @Twitter/X
            </a>
            <a href="https://discord.com/users/azharaio" target="_blank" rel="noopener noreferrer" 
              className="hover:text-white transition-colors duration-300">
              @Discord
            </a>
            <a href="https://github.com/azhrdev" target="_blank" rel="noopener noreferrer" 
              className="hover:text-white transition-colors duration-300">
              @Github
            </a>
          </div>
        </div>
      </div>

      <Routes>
        <Route path="/origins" element={<StoryWindow isOpen={true} onClose={handleWindowClose} />} />
        <Route path="/laboratory/*" element={<WorksContent isOpen={true} onClose={handleWindowClose} />} />
        <Route path="/chronicles/*" element={<ChroniclesContent isOpen={true} onClose={handleWindowClose} />} />
        <Route path="/contact" element={<ContactWindow isOpen={true} onClose={handleWindowClose} />} />
      </Routes>
      <MusicPlayer />
    </div>
  );
};

export default HomePage;